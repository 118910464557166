import { RouteRecordRaw } from "vue-router";
import HorizontalLayout from '@/layout/horizontalLayout/Index.vue';
const reagentConsumablesRouter: Array<RouteRecordRaw> = [
    {
        path: "/reagentConsumables",
        component: HorizontalLayout,
        redirect: "/reagentConsumables/dashboard",
        name: "reagentConsumables",
        meta: {
            title: "耗材试剂系统",
            affix: true,
        },
        children: [
            {
                path: "dashboard",
                component: () => import("@reagentConsumables/views/dashboard/index.vue"),
                name: "reagentConsumablesDashboard",
                meta: {
                    title: "试剂耗材首页",
                    affix: true,
                },
            },

            //#region 物料损耗
            {
                path: '/reagentConsumables/material/reagentLibrary',
                component: () => import('@reagentConsumables/views/material/reagentLibrary.vue'),
                name: 'reagentLibrary',
                meta: {
                    title: '试剂库',
                }
            },
            {
                path: '/reagentConsumables/material/projectLibrary',
                component: () => import('@reagentConsumables/views/material/projectLibrary.vue'),
                name: 'projectLibrary',
                meta: {
                    title: '项目库',
                }
            },
            {
                path: '/reagentConsumables/material/connectInventory',
                component: () => import('@reagentConsumables/views/material/connectInventory.vue'),
                name: 'connectInventory',
                meta: {
                    title: '试剂包关联',
                }
            },
            {
                path: '/reagentConsumables/material/warehouseRequisition',
                component: () => import('@reagentConsumables/views/material/warehouseRequisition.vue'),
                name: 'warehouseRequisition',
                meta: {
                    title: '仓库领用',
                }
            },
            {
                path: '/reagentConsumables/material/inventoryRecords',
                component: () => import('@reagentConsumables/views/material/inventoryRecords.vue'),
                name: 'inventoryRecords',
                meta: {
                    title: '损耗预览',
                }
            },
            {
                path: '/reagentConsumables/material/consumablesStatistics',
                component: () => import('@reagentConsumables/views/material/consumablesStatistics.vue'),
                name: 'publicSystemsBarcodeManagement',
                meta: {
                    title: '损耗统计',
                }
            },
            //#endregion
        ],
    },
];
export default reagentConsumablesRouter;
